<template>
  <div class="home home--design">

    <!-- ROUTER CONTENT SHOULD GO HERE -->
    <!-- <div class="container">
      <h1>LEARN</h1>
      <router-view/>
    </div> -->
    <div class="container">
      <!-- IF MENU -->
      <div class="row" v-if="$route.name === 'menu'">
        <div class="col">
          <router-view/>
        </div>
      </div>

      <div class="row" v-else-if="$route.name === ''">
        <div class="col">
          <router-view/>
        </div>
      </div>

      <!-- IF NO MENU -->
      <div class="row" v-else>
        <div class="col-md-3">
          <sidebar-menu :routeObj="$store.state.academicData.design" />
        </div>
        <div class="col-md-9 mt-4">
          <router-view/>
          <!-- <div class="content-header">
            <h2>Template</h2>
          </div> -->
        </div>
      </div>
    </div>

    <!-- MAIN NAV -->
    <nav class="navbar main-nav">
      <div class="container">
        <router-link class="main-nav__item" to="/students-resilience/community">COMMUNITY</router-link>
        <router-link class="main-nav__item" to="/students-resilience/personal">PERSONAL</router-link>
        <router-link class="main-nav__item" to="/students-resilience/academic">ACADEMIC</router-link>
        <router-link class="main-nav__item" to="/students-resilience/career">CAREER</router-link>
      </div>
    </nav>
  </div>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
    SidebarMenu
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },

  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>
